@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

/* ====================================================
    Fonts
   ==================================================== */

@font-face {
	font-family: "Karbon-Regular";
	src: url("../fonts/karbon-regular-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-regular-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-regular-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-regular-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-regular-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-regular-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-regular-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Karbon-RegularItalic";
	src: url("../fonts/karbon-regularitalic-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-regularitalic-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-regularitalic-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-regularitalic-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-regularitalic-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-regularitalic-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-regularitalic-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Karbon-Light";
	src: url("../fonts/karbon-light-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-light-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-light-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-light-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-light-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-light-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-light-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Medium";
	src: url("../fonts/karbon-medium-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-medium-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-medium-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-medium-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-medium-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-medium-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-medium-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Bold";
	src: url("../fonts/karbon-bold-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-bold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-bold-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-bold-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-bold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-bold-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-bold-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Semibold"; 
	src: url("../fonts/karbon-semibold-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-semibold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-semibold-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-semibold-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-semibold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-semibold-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-semibold-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "ManusSmooth";
	src: url("../fonts/ManusSmooth.ttf") format("truetype"); /* Safari, Android, iOS */  
	font-weight: normal;
	font-style: normal;
}

/* ====================================================
    Global
   ==================================================== */

a:focus, button:focus {
    outline: none !important;
}

body{
	background-image:url('../img/bg-spotlight-all.png');
	background-repeat:no-repeat;
	background-position:-1300px 0;
	background-size:2471px;
	background-color:#2f1b0d;
	font-size:24px;

	&#home{
		background-image:url('../img/bg-spotlight.jpg');
		background-position:-1300px 0;
		background-size:2889px;
		background-attachment: fixed;
	}

	.container{
		padding-top:62px;
	}

	&#home .container{
		padding-top:0px;

		.home-title{
			padding-top:62px;
		}
	}
}
h1.title {
	font-size:72px;
	color:$primary-color;
	text-shadow: 0 0 21px #ac4100;
}
h2.title{
	font-size:68px;
}
h2.title2{
	font-size:170px;
	line-height:0.8em;
	margin-bottom:0;
	margin-top:60px;
}
h3.title{
	font-size:84px;
}
h4.title{
	font-size:48px;
}
h4.subtitle{
	font-size:47px;
	margin-bottom:20px;
}
h4.title2{
	font-size:94px;
	line-height: 0.9em;
}
h4.subtitle2{
	font-size:42px;
}
h4.line{
	border-bottom: 2px solid $primary-color;
	padding-bottom:13px;
	margin-bottom:30px;
	display: inline-block;
}
h5{
	font-family: "Karbon-Semibold"; 
	color:$primary-color;
	text-transform:uppercase;
	line-height:1.0em;
}
h5.title{
	font-size:28px;
	margin-bottom:0;
}
h6{
	font-family: "Karbon-Semibold";
	line-height:1.1em;
	text-transform:uppercase;
}
h4 sup{
	font-family: trade-gothic-next-condensed, sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size:55%;
	top: -0.65em;
}
p.title{
	margin-bottom:0;
	font-size:36px;
	color:$white;
}
b{
	font-family: "Karbon-Semibold";
}
strong{
	font-family: "Karbon-Bold";
}
sup{
	top: -0.4em;
	font-size:60%;
	font-family: "Karbon-Regular";
}

.orange-txt{
	color:$primary-color;
}
.white-txt{
	color:$white;
}
.start{
	font-size:24px;
	line-height:1.2em;

	strong{
		color:$white;
	}
}

.headline{
	font-size:36px;
	// text-decoration:underline;
	color:$primary-color;
}

ul.list {
	color:$primary-color;

	li {
		margin-bottom: 10px;
	}
}

a.link {
	color:$success-color;
	text-decoration:underline;
}

.ul-center ul{
	list-style-position: inside;
	margin-left:0;
}

.margin-b0{
	margin-bottom:0;
}


/* ====================================================
    Buttons
   ==================================================== */

.button{
	text-transform: uppercase;

	&:hover{
		opacity:0.5;
	}
}

// .button.appear{
// 	&:hover{
// 		-webkit-transition: none;
// 		 -moz-transition: none;
// 			-ms-transition: none;
// 			 -o-transition: none;
// 					transition: none;
// 	}
// }
// .button.appear.visible{

// 	&:hover{
// 		opacity:0.5;
// 		-webkit-transition: none;
// 		 -moz-transition: none;
// 			-ms-transition: none;
// 			 -o-transition: none;
// 					transition: none;
// 	}
// }
.Dlines{
	padding: 0.75em 1.3em 0.6em;
}

.button.arrow {
	text-align:left;
	position: relative;
	padding-right: 3em;

	&:after {
	  content: '';
	  position: absolute;
	  background-image:url('../img/btn_arrow.png');
	  background-size:17px;
	  width:17px;
	  height:15px;
	  color:$white;
	  top: 20px;
	  right: 12px;
	}
}

.button.search-plus,.button.search-minus {
	text-align:left;
	padding: 1.3em 1.3em 1.2em 4.8em;
	border: 6px solid #e6e269;
	position:relative;
	font-size:31px;	
	text-transform:inherit;
	font-family: "Karbon-Regular";
	border-radius: 90px;
	width:595px;

	&.Dline{
		padding-top: 0.77em;
		padding-bottom:0.72em;
	}
}
.button.search-plus:before{
	content: '';
	position: absolute;
	background-image:url('../img/btn_plus.png');
	background-size:66px;
	width:66px;
	height:66px;
	color:$white;
	top: 22px;
	left: 30px;
}
.button.search-minus:before{
	content: '';
	position: absolute;
	background-image:url('../img/btn_minus.png');
	background-size:66px;
	width:66px;
	height:3px;
	color:$white;
	top: 55px;
	left: 30px;
}

.button.solid{
	font-size: 18px;
	background-color: $secondary-color;
	color: $black;
	padding: 1.2em 1.3em 0.9em;
} 

.button.btn_down{
	border:none;
	position: relative;
	padding: 0 1.5em;
	margin-top: 1.2em;
	margin-bottom: 1em;

	&:before, &:after{
		content: '';
		position: absolute;
		background-image:url('../img/arrow_down_yellow.png');
		background-size:9px;
		width:9px;
		height:31px;
		top:0;
	}
	&:before{
		left:0;	
	}
	&:after{
		right:0;	
	}
}

/* ====================================================
    Top Navigation
   ==================================================== */

.top-bar.sticky{
	z-index:5;
}
.top-bar ul {
	background-color: transparent;

	ul{
		background-color: rgba(28, 16, 8, 0.90);
	}
}

.dropdown{
	margin-left: auto;
	margin-right:auto;
	&.menu {
		a {
			font-family: "Karbon-Medium";
			color:$white;
			font-size: 21px;
			text-transform:uppercase;

			&:hover,&:focus,&.active{
				color:rgba(255,255,255,0.35);
			}

			&:after{
				content:'/';
				color:rgba(255,255,255,0.25);
				padding-left:2rem;
			}


		}

		.submenu {
			li{
				padding-left: 0.5em;
				padding-right: 0.5em;
			}
			a {
				padding:0.8rem 1rem 0.6rem;
				font-size: 18px;
				border-bottom: 1px solid $primary-color;
				color:rgba(255,255,255,0.50);

				&:after{
					content:none;
					padding-left:0rem;
				}
				&.active,&:hover{
					color:$white;
				}
			}

			li:first-child a{
				padding-left:1rem;
			}	
			li:last-child a{
				padding-right:1rem;
				border-bottom: none;
			}
		}

		li:first-child a{
			padding-left:0;
		}
		li:last-child a{
			padding-right:0;

			&:after{
				content:'';
				padding-left:0;
			}
		}
	}
}

.is-dropdown-submenu-parent a::after{
	position:relative;
	right:0;
}

.dropdown.menu > li.is-active > a{
	color:inherit;
}
.dropdown.menu > li.opens-right > .is-dropdown-submenu{
	left:-24px;
	top:117%;
}

.menu-icon{
	width:50px;
	height:32px;

	&:after{
		height:7px;
		box-shadow: 0 12px 0 $secondary-color, 0 25px 0 $secondary-color;
		border-radius:10px;
	}
	&:hover::after{
		box-shadow: 0 12px 0 $secondary-color, 0 25px 0 $secondary-color;
	}
}

/* ====================================================
    Content
   ==================================================== */
.home-title{

	img{
		position:relative;
		z-index:1;
		margin-bottom:-30px;
	}

	.eyebrow {
		font-family: "ManusSmooth";
		text-transform:uppercase;
		font-size: 60px;
		margin-bottom:-17px;
		color:$white;
	}
}

.graphic-home1{
	background-color:#25150a;
	padding-top: 30px;
	padding-bottom: 5px;

	p{
		text-align:left;
		padding-left: 30px;
		padding-right: 30px;
	}
}

.cta_icons{
	img{
		height: 88px;
		margin-bottom: 21px;
		margin-top: 43px;
	}
	.icon_title{
		font-family: "Karbon-Semibold"; 
		font-size: 22px;
		text-transform:uppercase;
		display:block;
		height: 86px;
		color:$white;
		line-height: 1.05em;
	}

	.button{
		width:98%;
		line-height:0.9em;
	}

	&.cta_icons02{
		.cell{
			border-right:1px solid $white;

			&:last-child{
				border-right: none;
			}
		}
		.icon_title{
			height:auto;
		}
		.button {
			width:auto;
			padding-right:1.8em;
			padding-left:1.8em;
		}
		.Dlines{
			padding:0.75em 2em 0.6em;
		}
	}
}

.footnotes{
	font-size:18px;
}

footer {
	font-size: 22px;
	border-top: 4px solid $white;
	margin-top: 30px;
	padding-top: 29px;
	margin-bottom:50px;

	p{
		a {
			color:#faf873;
			text-decoration: underline;

			&:hover{
				color:#faf873;
			}
		}
	}

	.menu{
		
		a {
			font-size:18px;
			font-family: "Karbon-Semibold";
			color:#e6e269;
			padding:0rem 0.7rem;

			&:hover{
				text-decoration:underline;
			}

			&:after{
				content:'|';
				color:rgba(255,255,255,0.25);
				padding-left:1.5rem;
			}
		}

		li:first-child a{
			padding-left:0;
		}
		li:last-child a{
			padding-right:0;

			&:after{
				content:'';
				padding-left:0;
			}
		}
	}
	.personal{
		font-size: 24px;
	}
}

.container-box{
	background-color:rgba($black,0.25);
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 12px;

	ul {
		margin-bottom:0;
	}

	&.fog{
		background-image:url('../img/bg_fog.png');
		background-repeat:no-repeat;
		background-size:1236px;
		background-position:center 180px;
	}

	&.fog02{
		background-image:url('../img/bg_fog02.png');
		background-repeat:no-repeat;
		background-size:1500px;
		background-position:-248px 571px;
	}

	&.darker{
		background-color:rgba($black,0.50);
	}
	&.lighter{
		background-color:rgba($primary-color,0.10);
	}
}

.video-container{
	background-image:url('../img/bg_stripe.jpg');
	background-repeat:repeat-x;
	background-position:0px;
}

.video-inside{height:568px}

p.stripe {
    position: relative;
    font-size: 30px;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    margin-top: -65px;
	margin-bottom: 26px;
}
p.stripe:before, p.stripe:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 3px;
    content: '\a0';
    background-color: $primary-color;
    margin-left:3.2%;
}
p.stripe:before {
    margin-left: -53%;
    text-align: right;
}

.callout {
	text-align:center;
	border-top:12px solid $primary-color;
	border-bottom:12px solid $primary-color;
	font-size:30px;
	margin-bottom:20px;
}

.symptoms-content{
	position:relative;
	z-index:2;
	display: inline-block;
}
.symptoms-man-container{
	position:relative;
	margin-top:-270px;
	z-index:0;

	.symptoms-man{
		margin-bottom: -148px;
	}
}
.container-box.symptoms-man-margin{
	margin-bottom: 118px;
}



.modal_box{
	display:block;
	width:74px;
	height:66px;
	// width:6.35%;
	position:absolute;
	z-index:1;

	&.modal_nervousSystem{
		top:5%;
		left:41.3%;
	}
	&.modal_eyes{
		top:9.4%;
		left:48.45%;
	}
	&.modal_mouth{
		top:17.15%;
		left:46.65%;
	}
	&.modal_heart{
		top:41.6%;
		left:52.85%;
	}
	&.modal_liver{
		top:61.25%;
		left:43.7%;
	}
	&.modal_kidney{
		top:63.6%;
		left:54.2%;
	}
	&.modal_gi{
		top:69.65%;
		left:49.8%;
	}
}


/* ====================================================
    Search Algorithm
   ==================================================== */
.search-title b{
	font-size:32px;
	font-family: "Karbon-Medium";
}
.search-question{
	font-size:40px;
	margin-bottom:0px;
}
.search-select{
	font-size:28px;
	font-family: "Karbon-Medium";
	color:$white;
}
.finish h4{
	font-size:90px;
}
.q-hide,.b-hide{
	display:none;
}
.shrink {
	opacity:0.25;

	.search-question{
		font-size: 24px;
	}
	.search-select, .button{
		display:none;
	}

	&.intro img{
		height:43px;
	}

}

.intro img{
	margin-bottom:10px;
}
.q-marrow img,.q-marrow-consider img, .q-marrow-endomyocardial img, 
.q-organ img, .q-ttr-mass img, .finish img{
	margin-bottom:16px;
}
.q-marrow-endomyocardial.shrink img,.q-ttr.shrink img,.q-ttr-endomyocardial.shrink img{
	margin-bottom:14px;
}
.q-serum.shrink, .q-marrow.shrink, .q-marrow-endomyocardial.shrink,.q-ttr.shrink,
.q-ttr-endomyocardial.shrink,.q-organ.shrink,.q-ttr-mass.shrink{
	margin-bottom:-18px;
}
.q-marrow-consider.shrink{
	margin-bottom:10px;
}

/* ====================================================
    Tabs
   ==================================================== */
.tabs {
	margin: 3rem 0 2rem;
}
.tabs-title{
	width:33%;
	border-right: 1px solid white;

	img.float-left{
		margin-right: 20px;
	}

	&:first-child > a{
		padding-left: 1.5rem;
	}
	&:last-child {
		border-right:none;
		 > a{
			padding-right: 0rem;
		}

	}

}
.tabs-title > a {
	text-decoration: none;
	font-family: "Karbon-Medium";
	text-transform:uppercase;
	padding:0rem 2rem 0rem 3.2rem;

	

	span{
		color:$success-color;
	}
}
.tabs-title > a:focus, .tabs-title > a[aria-selected='true'],.tabs-title > a:hover {
	opacity:0.45;	
}

// .tabs-content .tabs-panel {
//   animation-duration: 2s;
//   animation-name: fadeOut;
// }
// .tabs-content .tabs-panel.is-active {
//   animation-duration: 2s;
//   animation-name: fadeIn;
// }
// @keyframes fadeIn {
// 	from { opacity: 0; }
// 	to { opacity: 1; }
// }
// @keyframes fadeOut {
// 	from { opacity: 1; }
// 	to { opacity: 0; }
// }

// .tabs-content .tabs-panel.is-active .appear2 {
// 	opacity:0;
// 	-webkit-transition: all 1s ease-in-out;
// 		 -moz-transition: all 1s ease-in-out;
// 			-ms-transition: all 1s ease-in-out;
// 			 -o-transition: all 1s ease-in-out;
// 					transition: all 1s ease-in-out;
// }
// .tabs-content .tabs-panel.is-active .appear2.visible {
// 	opacity:1;
// 	-webkit-transform: none;
// 		 -moz-transform: none;
// 			-ms-transform: none;
// 			 -o-transform: none;
// 					transform: none;
// }

/* ====================================================
    Table
   ==================================================== */
thead{
	th{
		padding:0;
		font-family: "Karbon-Semibold";
		text-transform:uppercase; 
		font-weight:normal;
		line-height:1.0em;

		.thead-bg {
			padding: 0.6rem 0.625rem 0.5rem;
			background-color:rgba($primary-color,0.50);
			display:block;
			height:100%;
			margin-right:5px;
			min-height:78px;
		}
	}
}
th,td{
	vertical-align:top;
}

td:first-child{
	font-family: "Karbon-Semibold";
}
tbody{
	tr{
		border-bottom:1px solid $primary-color;

		&:nth-child(even){
			border-bottom:1px solid $primary-color;;
		}
	}

}

/* ====================================================
    Reveal Modal
   ==================================================== */
html.is-reveal-open.zf-has-scroll{
	overflow-y:hidden;
}
html.is-reveal-open{
	position:absolute;
}

.reveal {
	color:$white;

	h4{
		font-size:42px;
		margin-top:40px;
		margin-bottom:40px;
	}

	.button{
		width:100%;
	}

	&.symptoms{
		background-color:transparent;
		padding:0;
		overflow-y: initial;
		top:5px !important;
		// height:100%;
		width:100%;

		img.symp-info {
			// height:100%;
			// max-width:auto;
			// width:auto;
		}

		.close-button{
			top:90%;
			right:48%;
		}

		&#eyes, &#mouth{
			p{
				position: absolute;
				text-align: center;
				font-size: 0.96rem;
				top: 78.8%;
				width:100%;

				a {
					color:$white;
					text-decoration:underline;
				}	
			}		
		}
	}
}

#symptoms-test .reveal-overlay{
	background-color: rgba($black, 0.75);
}



/* ====================================================
    Journey Timeline
   ==================================================== */
$timeline-color: $white;
$timeline-transition: all 0.25s ease-in;
$timeline-item-card-bg: $white;
$timeline-item-font-color: $white;
$timeline-item-card: false;
$timeline-item-card-radius: $global-radius;

%clearfix {
  &::after,
  &::before {
    clear: both;
    content: '';
    display: block;
    width: 100%;
  }
}

// Timeline
.timeline {
  margin: 30px auto;
  padding: 0 10px;
  position: relative;
  transition: $timeline-transition;
  width: 100%;

  &::before {
    background: rgba($timeline-color, 0.35);
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 1px;
  }

  &::after {
    clear: both;
    content: '';
    display: table;
    width: 45px;
	height: 1px;
	background: rgba(255, 255, 255, 0.35);
	left: 48.2%;
	position: absolute;
	bottom: -1px;
  }

  a {
    color: $timeline-item-font-color;
    font-weight: bold;
    transition: $timeline-transition;

    &:hover {
      box-shadow: 0 1px 0px 0px $timeline-item-font-color;
      transition: $timeline-transition;
    }
  }

  .timeline-item {
    margin-bottom: 40px;
    position: relative;
    @extend %clearfix;

    &.tHeadline{
    	height:91px;
    }
    &.tHeadline2{
    	height:379px;

    	.tMiddle{
    		top: 5px;
    	}
    }
    &.tHeadline3{
    	height:100px;

    	.tMiddle{
    		padding-top: 20px;
    		padding-bottom: 20px;

    		&::before {
			    background: #4b2c0e;
			    content: '';
			    height: 100%;
			    left: 50.1%;
			    position: absolute;
			    top: 0;
			    width: 3px;
			  }
    	}
    }

    &.row01{
    	margin-bottom:-128px;

    	.timeline-content{
    		padding-top: 0px;
    		padding-bottom:0px;
    	}
    }
    &.row02{
    	margin-bottom:30px;
    }
    &.row03{
    	margin-bottom:-30px;
    }
     &.row04{
    	margin-bottom:0px;
    }
    &.row05{
    	margin-top:-65px;

    	.timeline-content{
    		margin-top: -65px;
    	}
    }
    &.row07{
    	margin-top:-110px;

    	.timeline-content{
    		margin-top: -110px;
    	}
    }
    &.row09{
    	margin-top:-50px;
    	margin-bottom: -79px;

    	.timeline-content{
    		margin-top: -50px;
    	}
    }
     &.row01-2{
    	margin-bottom:-272px;

    	.timeline-content{
    		padding-top: 0px;
    		padding-bottom:0px;
    	}
    }

    .timeline-icon {
      // background: $timeline-color;
      // border-radius: 50%;
      // height: 50px;
      left: 50%;
      margin-left: -55px;
      // overflow: hidden;
      position: absolute;
      top: 45%;
      width: 55px;

      &.tRight{
  		margin-left: 1px;
  		}
      &.tMiddle{
      	width: 420px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		left: 0;
		right:0;
		top: 0;

		&.tHeadline01{
			width: 373px;
		}
      }

      &.big{
      	width:121px;
      	margin-left: -121px;

      	&.tRight{
      		margin-left: 1px;
      	}
      }

      img,
      svg {
        // left: 14px;
        position: relative;
        // top: 11px;
      }

      svg {
        top: 14px;
      }
    }

    .timeline-content {
      padding: 20px;
      text-align: right;
      transition: $timeline-transition;
      width: 45%;

      &.bigMargin{
      	padding-right: 84px;
      }

      @if $timeline-item-card == true {
        background: $timeline-item-card-bg;
        border-radius: $timeline-item-card-radius;

        p {
          color: $black;
        }
      } @else {
        background: transparent;

        p {
          color: $timeline-item-font-color;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 0;
      }

      .timeline-content-date {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
      }

      .timeline-content-month {
        font-size: rem-calc(14);
        text-transform: uppercase;
        font-weight: 400;
      }

      &.right {
        float: right;
        text-align: left;

        &.bigMargin{
	      	padding-left: 84px;
	      }
      }
    }
  }
}

.path1_14,.path2_05{
	margin-bottom: 60px;
}

@include breakpoint(small only) {
	.timeline{
		&:before{
			width:0;
		}
		&:after{
			height:0;
		}
		.timeline-item{
			margin-bottom:80px;

			&.tHeadline{
				height:50px;
			}
			img{
				position:relative;
			}
			.timeline-icon{
				&.tMiddle{
					width:192px;

					&.tHeadline01{
						width:192px;
					}
				}
				&.big, &.tRight{
					display:none;
				}
			}
			.timeline-content{
				padding:0;
				width:70%;
				margin-left:auto;
				margin-right:auto;

				&.bigMargin{
					padding-right:0;
				}

				&.right{
					float:inherit;

					&.bigMargin{
						padding-left:0;
					}
				}
			}

			&.row01,&.row02,&.row03,&.row04,&.row01-2 {
				margin-bottom:80px;

				.timeline-icon{
					display:none;
				}
			}
			&.row05,&.row07,&.row09 {
				margin-bottom:80px;
				margin-top:inherit;

				.timeline-content{
					margin-top:0;
				}

				.timeline-icon{
					display:none;
				}
			}
			&.row01,&.tHeadline,&.row04,&.row07,&.row09,&.tHeadline2,&.row01-2 {
				&::before, &::after  {
			    background: rgba(255, 255, 255, 0.35);
			    content: '';
			    left: 50.1%;
			    position: absolute;
			    width: 1px;
			  }

			}
			&.row01 {
				&::before {
				    height: 24%;
				    top: -14%;
				}
				&::after {
					height: 42%;
					top: 100%;
				}
			}
			&.tHeadline {
				margin-bottom:80px;
				&::before {
				    height: 106%;
				    top: -107%;
				  }
				&::after {
					height: 109%;
					top: 96%;
				}
			}
			&.row04 {
				&::before {
				    height: 59%;
				    top: -40%;
				  }
				&::after {
					height: 192%;
					top: 121%;
				}
			}
			&.row07 {
				&::before {
				    height: 59%;
				    top: -22%;
				  }
				&::after {
					height: 11%;
					top: 100%;
				}
			}
			&.row09 {
				&::before {
				    height: 13%;
				    top: -19%;
				  }
				&::after {
					height: 23%;
					top: 107%;
				}
			}
			&.row10{
				margin-bottom:20px;
			}
			&.tHeadline2{
				height: 234px;
				margin-bottom:5px;

				&::before {
				    height: 24%;
				    top: -14%;
				}
				&::after {
					height: 11%;
					top: 87%;
				}
			}
			&.tHeadline3{
				height:50px;
				margin-bottom:60px;
				.tMiddle{
					padding-top: 10px;
					padding-bottom: 10px;
					&:before{
						width:0;
					}
				}
			}
			&.row01-2{
				margin-bottom:72px;

				&::before {
				    height: 10%;
				    top: -16%;
				}
				&::after {
					height: 10%;
					top: 104%;
				}
			}
			&.row01-3{
				margin-bottom:40px;
			}
		}
	}

	.path1_14{
		width:240px;
	}
	.path2_05{
		width:230px;
	}

	.container-box.lighter {
		background-color: rgba(0, 0, 0, 0.3);

		.timeline .timeline-item .timeline-icon.tMiddle{
			width:195px;
		}
	}

	.cta_icons{
		&.cta_icons02 {
			.icon_title{
				font-size:24px;
			}
			.button{
				font-size:18px;
				border: 4px solid #e6e269;
			}
			img{
				height: 90px;
			}
			.cell{
				border-right:none;
			}
		}
	}
}

@include breakpoint(medium only) {
	.timeline {
		.timeline-item {
			.timeline-icon{
				margin-left: -47px;
				width: 47px;

				&.big{
					width:99px;
					margin-left:-99px;
				}
			}
		}
	}
}

.tEnd{
	font-size:30px;
	color:$white;

	.button{
		font-size:28px;
		text-align:left;
		border-radius:80px;
		font-family: "Karbon-Regular";
		text-transform:inherit;
		padding: 0.55em 2.5em 0.4em 1.3em;
		position:relative;

		&:after{
			content: '';
			position: absolute;
			background-image:url('../img/btn_arrow2.png');
			background-size:15px;
			width:15px;
			height:27px;
			color:$white;
			top: 27px;
			right: 21px;
		}
	}
}


/* ====================================================
    Reveal animation on scroll
   ==================================================== */
.appear,.appear2,.appear3 {
	opacity: 0;
	-webkit-transition: all 1s ease-in-out;
		 -moz-transition: all 1s ease-in-out;
			-ms-transition: all 1s ease-in-out;
			 -o-transition: all 1s ease-in-out;
					transition: all 1s ease-in-out;
}
.appear.visible,.appear2.visible, .appear3.visible {
	opacity: 1;
	-webkit-transform: none;
		 -moz-transform: none;
			-ms-transform: none;
			 -o-transform: none;
					transform: none;
}





.man.sticky{
	z-index:0;
}

/* ====================================================
    Media Queries
   ==================================================== */
/* Large and up */
@media screen and (min-width: 64em) {
	.top-bar.sticky.is-stuck{
		background-color: rgba(28, 16, 8, 0.80);
		z-index:6;
	}
	#imagesequence img{
		max-width:200%;
	}
	#tab1,#tab2,#tab3{
		padding-top: 9px;
		padding-bottom: 6px;
	}
	// #tab3 img{
	// 	margin-top:13px;
	// }

	.reveal{
		&.symptoms{
			// height:100%;
			img.symp-info {
				// height:100%;
				// max-width:auto;
				width:830px;
			}
		}
	}
}

@media screen and (max-width: 1024px){

	.reveal{
		&.symptoms{
			height:auto;

			img.symp-info {
				height:auto;
			}

			&#eyes, &#mouth{
				p{
	    			top: 79%;
	    			// font-size: 1.5vmin;
				}		
			}
		}
	}

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
	.modal_box{
		// width:99px;
		// height:88px;
		width:13%;

		&.modal_nervousSystem{
			top:3%;
			left:27.5%;
		}
		&.modal_eyes{
			top:7.5%;
			left:42.15%;
		}
		&.modal_mouth{
			top:15.22%;
			left:39%;
		}
		&.modal_heart{
			top:40.25%;
			left:51.5%;
		}
		&.modal_liver{
			top:60.3%;
			left:32.25%;
		}
		&.modal_kidney{
			top:62.7%;
			left:53.95%;
		}
		&.modal_gi{
			top:69%;
			left:42.4%;
		}
	}

	.reveal.symptoms{
		&#eyes, &#mouth{
			p{
    			top: 72.3vmin;
    			// font-size: 1.9vmin;
			}		
		}
	}
	.video-inside{height:368px}
}


/* Small only */
@media screen and (max-width: 39.9375em) {
	h4.title2{
		font-size:48px;
	}
	h4.subtitle2{
		font-size:21px;
	}
	p.title{
		font-size:26px;
	}
	.start{
		margin-bottom: -25px;
	}
	.tEnd{
		font-size:18px;

		.button{
			font-size:16px;
			padding: 0.45em 2em 0.3em 1.3em;

			&:after{
				background-size:7px;
				width:7px;
				height:13px;
				top: 16px;
    			right: 16px;
			}
		}
	}

	.video-inside{height:268px}

	.container-box{
		&.darker{ background-color: rgba(0, 0, 0, 0.3);}
		&.fog02{background-position: -1022px 200px;}
	}

	.text-align-mbl-left{
		text-align:left;
	}
	.mbl-left{
		text-align: left;
	}
	.mbl-center{
		text-align:center;
	}
	.mbl-ul-center, .mbl-center {
		ul{
			list-style-position: inside;
			margin-left:0;
		}
	}
	.tabs-title{
		min-height: 153px;
	}
	#symptoms.tabs-panel{
		padding:0;

	}


	.modal_box{
		// width:55px;
		// height:49px;
		width:13%;

		&.modal_nervousSystem{
			top:2.9%;
			left:27.4%;
		}
		&.modal_eyes{
			top:7.4%;
			left:42%;
		}
		&.modal_mouth{
			top:15.2%;
			left:38.5%;
		}
		&.modal_heart{
			top:40.1%;
			left:51.3%;
		}
		&.modal_liver{
			top:60.15%;
			left:32.5%;
		}
		&.modal_kidney{
			top:62.5%;
			left:54%;
		}
		&.modal_gi{
			top:68.95%;
			left:42.1%;
		}
	}
	.reveal.symptoms{
		overflow:hidden;
		img.symp-info{
			max-width:178%;
			margin-left:-38.5%;
			height:auto;
		}
		.close-button{
			top:84%;
			right:43%;
		}

		&#eyes, &#mouth{
			p{
				font-size: 3.4vmin;
    			top: 128.9vmin;
			}		
		}
	}

}

/* Small and Medium only */
@media screen and (max-width: 63.9375em) {
	body{
		background-image:url('../img/bg-spotlight-mbl.png');
		background-repeat:repeat-x;
		background-position:top;
		background-size:inherit;
		padding-top:23px;
		padding-right: 0.6rem;
    	padding-left: 0.6rem;
    	font-size:16px;

    	.container, &#home .container .home-title{
			padding-top:30px;
		}

		&#home{
			background-image:url('../img/bg-spotlight-mbl.png');
			background-position:top;
			background-size:inherit;

			.container {
				padding-top:0;
			}
		}

		
	}

	h1.title{
		font-size:35px;
		margin-bottom: 30px;
	}
	h2.title2{
		font-size: 64px;
	}
	h3.title{
		font-size:55px;
		margin-bottom:30px;
		img{
			width:60px;
		}
	}
	h4.subtitle{
		font-size:18px;
	}
	
	.container-box {
		h4.title{
			margin-bottom: 40px;
			font-size:24px;
		}
		&.lighter {
			h4.title{
				margin-bottom: 20px;
			}
		}
	}
	h5.title{
		font-size:22px;
	}
	.footnotes.serum{
		font-size:16px;
	}
	.mbl_fSize{
		font-size:24px;
	}
	.button{
		font-size: 14px;
		border: 3px solid #e6e269;

		&.arrow{
			&:after{
				background-size: 14px;
			    width: 14px;
			    height: 13px;
			    top: 17px;
			}
		}
	}
	.button.solid{
		font-size:13px;
		margin-top: 20px;
	}
	.home-title {
		.eyebrow{
			font-size: 39px;
			margin-bottom: -8px;
		}
	}

	#symptoms-test .container-box.darker{
		    margin-right: -0.6rem;
    		margin-left: -0.6rem;
	}

	#search.tabs-panel,#subtype.tabs-panel{
		padding:1rem 0;

		h4.line{
			font-size:19px;
		}
	}
	.search-title{
		font-size: 14px;

		b{
			font-size: 18px;
		}
	} 
	.intro img{
		width: 5px;
	}
	.shrink.intro img{
		height: 24px;
	}
	.search-question{
		font-size:18px;
	}
	.shrink .search-question{
		font-size:14px;
	}
	p.search-select{
		font-size:13px;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
	.button.search-plus, .button.search-minus{
		width:289px;
		font-size: 16px;
		border:4px solid #e6e269;
		padding: 1.3em 1.3em 1.2em 3.8em;
	}
	.q-marrow img, .finish img,.q-ttr-endomyocardial img, .q-ttr img, 
	.q-marrow-endomyocardial img, .q-marrow-consider img, .q-organ img, .q-ttr-mass img{
		width:21px;
		margin-bottom: 8px;
		margin-top:8px;
	}
	.finish h4{
		font-size:40px;
	}
	.button.search-plus:before{
		background-image:url("../img/btn_plus_mbl.png");
		width:31px;
		height:31px;
		background-size:31px;
		top: 12px;
    	left: 17px;
	}
	.button.search-minus:before{
		background-image:url("../img/btn_minus_mbl.png");
		width:31px;
		height:3px;
		background-size:31px;
		top: 27px;
    	left: 17px;
	}

	.callout{
		font-size:16px;
		padding: 1rem;
	}
	.headline{
		font-size:18px;
		margin-bottom:0.5rem;
	}


	/* mobile navigation */
	$offcanvas-full-screen-bg: #23140a;
	$offcanvas-full-screen-side: left;
	$offcanvas-full-screen-closebutton-size: 5rem;

	.offcanvas-full-screen {
	  @include off-canvas-base($background: $offcanvas-full-screen-bg, $transition: 0.3s ease-in-out);
	  @include off-canvas-position($position: $offcanvas-full-screen-side);
	  width: 100%;
	    -webkit-transform: translateX(-100%);
	    -ms-transform: translateX(-100%);
	    transform: translateX(-100%);
	}

	.offcanvas-full-screen-inner {
	  padding: 1rem 0rem;
	  text-align: center;
	}

	.offcanvas-full-screen-menu {
	  @include menu-base;
	  @include menu-direction(vertical);
	  text-align:left;
	  text-transform:uppercase;
	  font-size:18px;
	  font-family: "Karbon-Medium";

	  li{
	  	border-bottom:2px solid $primary-color;

	  	&.open, &.has-submenu-toggle.active{
	  		background-color:$black;
	  	}

	  	.submenu-toggle::after{
	  		font-size: 36px;
    		font-family: "Karbon-Medium";
    		line-height:0;
    		content:'+';
    		color:$secondary-color;
    		border-color:transparent;
    		border:none;
    		top: 29px;
		   	right:37px;
		   	position:absolute;
	  	}

	  	.submenu-toggle[aria-expanded='true']::after{
	  		content:'-';
	  		transform:none;
	  	}

	  	ul.vertical.menu {
	  		display:none;

	  		li {
	  			border-bottom:none;
	  			width:100%;
	  			background-color:$black;

	  			a{
	  				padding: 0.7rem 1rem 0.4rem 2.8rem;
	  				color:rgba(255,255,255,0.35);

	  				&.active{
	  					color:$white;
	  				}
	  			}
	  			&:first-child a{
	  				padding-top: 0rem;
	  			}
	  			&:last-child a{
	  				padding-bottom: 1rem;
	  			}
	  		}
	  	} 
	  }

	  a {
	    color: $white;
	    padding: 1.4rem 1rem 1.1rem 1.5rem;

	    &.active {
	    	background-color:$black;
	    	color:$primary-color;
	    }

	    &:hover {
	      color: darken($white, 30%);
	    }
	  }
	  .add-links{
	  	font-size:16px;

	  	li {
	  		border-bottom:none;
	  		a{
	  			color:#e2c9b4;
	  			padding: .8rem 1rem 0.5rem 2.8rem;
	  		}
	  	}
	  }
	}

	.offcanvas-full-screen-close {
	  color: $secondary-color;
	  font-size: $offcanvas-full-screen-closebutton-size;

	  &:hover {
	    color: darken($white, 30%);
	  }
	}
	/* END mobile navigation */


	hr{
		border-bottom:2px solid $white;
		margin: 1.5rem auto;
	}

	.wax-man{
		margin-bottom: -112px;
    	position: relative;
	}	
	.callout_mbl{
		width:100%;
	}

	.graphic-home1 small{
		font-size:16px;

		p{
			line-height: 1.0;
		}
	}

	
	.cta_icons {
		img {
			height:58px;
			margin-bottom: 18px;
		}
		.icon_title{
			font-size:15px;
			height: 77px;
		}
		.button{
			font-size:11px;
		}
	}

	.lead{
		font-size:16px;
		line-height:1;
	}

	#guide_framework.reveal {
		padding: 2rem 1rem;
		width: 90%;
		margin-left: auto;
		height:auto;
		min-height:auto;

		h4{
			margin-top: 31px;
    		margin-bottom: 27px;
		}

		.button{
			font-size:20px;
			padding: 0.8em 0.7em 0.6em;
		}
	}

	p.stripe{
		margin-top: -52px;
		margin-right: -20px;
		margin-left: -20px;

		img{
			width:180px;
		}
		&:before, &:after{
			height:2px;
		}
	}

	.table-copy p{
		font-size: 16px;
	}
	table{
		font-size: 16px;
	}
	thead th{
		width:200px;
	}

	
	.tabs-title img.float-left{
		margin-right:auto;
		margin-left: auto;
		float:none !important;
		margin-bottom: 15px;
		display:block;
	}
	.tabs-title > a{
		font-size:16px;
		text-align:center;
		padding: 0rem 0.5rem 0rem 0.5rem;
	}
	.tabs-title:first-child > a {
		padding-left:0.5rem;
	}
	.symptoms-man-container{
		margin-top:0px;
		margin-left: -5.5%;
		margin-right: -5.5%;
		
	}

	footer{
		font-size:16px;
		.personal{
			font-size:inherit;
		}
		.menu {
			margin-bottom:30px;

			a {
				padding: 0rem 0.25rem;

				&:after{
					padding-left:0.5rem;
				}
			}
		}
		img{
			width: 289px;
		}
	}

}